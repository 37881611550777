<template>
    <div class="terms-conditions ptb-100">
        <div class="container">
            <div class="single-privacy">
              <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-12">
                  <h6>Impressum gemäß § 5 TMG</h6>
                  <p>
                    <b>CFTools Software GmbH</b><br>
                    Kiem-Pauli-Straße 8<br>
                    84036 Landshut<br>
                    Bayern, Deutschland<br>
                  </p>
                  <p>
                    Telefon: +49 871 205490-40<br>
                    Fax: +49 871 78049973<br>
                    E-Mail: info@cftools.software
                  </p>
                  <p>
                    Geschäftsführer: Philipp Joos<br>
                    Registergericht: Amtsgericht Landshut, HRB 12340<br>
                    Steuernummer: 132/123/30595<br>
                    UST-ID § 27a UStG: DE336495434<br>
                  </p>
                  <p>
                    UK VAT ID: 378 0103 08
                  </p>
                  <p>
                    RU IIN: 9909577289<br>
                    Weitere Informationen über den russischen Steuersatz finden Sie hier:<br>
                    - <a href="https://www.nalog.ru/rn77/" target="_blank">https://www.nalog.ru/rn77/</a><br>
                    - <a href="https://lkioreg.nalog.ru/en/registry" target="_blank">https://lkioreg.nalog.ru/en/registry</a>
                  </p>
                  <p>
                    Plattform der EU-Kommission zur Online-Streitbeilegung: <a href="https://www.ec.europa.eu/consumers/odr" target="_blank">https://www.ec.europa.eu/consumers/odr</a>
                  </p>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ImprintContent'
}
</script>