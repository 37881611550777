<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Impressum" />
        <ImprintContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Layout/Navbar'
import PageTitle from '../components/Common/PageTitle'
import ImprintContent from '../active-components/Legal/Imprint'
import Footer from '../components/Layout/Footer'

export default {
    components: { 
        Navbar,
        PageTitle,
      ImprintContent,
        Footer,
    }
}
</script>